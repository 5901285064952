import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { ProductBreadcrumbs } from '../components/ProductBreadcrumbs';
import { ComponentSource } from '../components/ComponentSource';
import { LayoutKometa } from '../layouts/LayoutKometa';
import { ProductKometa } from '../products/kometa';
import { componentsCategoryTitleCase } from '../utils/string';

export default ({ data, pageContext }) => {
  const location = useLocation();

  const items = data.allMdx.edges;

  const products = {
    kometa: { ...ProductKometa, layout: LayoutKometa },
  };

  const activeProduct = products[pageContext.product];

  const Layout = activeProduct.layout;

  return (
    <Layout>
      <ProductBreadcrumbs
        productTitle={activeProduct.title}
        productUrl={activeProduct.url}
        breadcrumbs={[
          { url: `${activeProduct.url}/components`, title: 'Components' },
          {
            url: location.pathname,
            title: componentsCategoryTitleCase(pageContext.category),
          },
        ]}
      />
      {items.map((item, index) => (
        <section key={index} className="mb-10">
          <MDXProvider
            components={{
              ComponentSource: (props) => (
                <ComponentSource
                  preview={item.node.frontmatter.preview}
                  {...props}
                />
              ),
            }}
          >
            <MDXRenderer>{item.node.body}</MDXRenderer>
          </MDXProvider>
        </section>
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
  query($product: String!, $category: String!) {
    allMdx(
      filter: {
        frontmatter: { product: { eq: $product }, category: { eq: $category } }
      }
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            preview {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
