import React, { useState, useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Image from 'gatsby-image/withIEPolyfill';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie';
import { CodeBlock } from './CodeBlock';
import { Modal } from './Modal';
import { SubscribeForm } from './SubscribeForm';

const TabButton = ({ title, active, children, onClick }) => (
  <button
    title={title}
    aria-label={title}
    className={classNames(
      'px-2 sm:px-3 py-px sm:py-1 text-sm sm:text-base rounded focus:outline-none focus:shadow-outline transition-all duration-200',
      {
        'bg-indigo-100 text-indigo-700': active,
        'text-gray-700': !active,
      }
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

const Tab = ({ tab, html, vue, react, preview }) => {
  switch (tab) {
    case 'Preview': {
      return (
        <Image
          fluid={preview.childImageSharp.fluid}
          draggable={false}
          className="w-full"
        />
      );
    }
    case 'HTML': {
      return (
        <CodeBlock
          language="html"
          stripLast={false}
          withMarginBottom={false}
          rounded={false}
        >
          {html}
        </CodeBlock>
      );
    }
    case 'React': {
      return (
        <CodeBlock
          language="jsx"
          stripLast={false}
          withMarginBottom={false}
          rounded={false}
        >
          {react}
        </CodeBlock>
      );
    }
    case 'VueJS': {
      return (
        <CodeBlock
          language="html"
          stripLast={false}
          withMarginBottom={false}
          rounded={false}
        >
          {vue}
        </CodeBlock>
      );
    }
    default: {
      return <div />;
    }
  }
};

const SUBSCRIBE_COOKIE = 'mail_subscribed';

export const ComponentSource = ({
  vue,
  react,
  html,
  preview,
  hideCopyFor = [],
}) => {
  const { site, modalImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        modalImage: file(absolutePath: { regex: "/heart.png/" }) {
          childImageSharp {
            fluid(maxWidth: 320, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  );

  const tabs = ['Preview', 'HTML', 'VueJS', 'React'];

  const [tab, setTab] = useState(tabs[0]);

  const computeSourceCode = useCallback(
    (source = '') =>
      source.replace(
        /{{ assetsPath }}/g,
        `${site.siteMetadata.siteUrl}/assets`
      ),
    [site.siteMetadata.siteUrl]
  );

  const sources = useMemo(
    () => ({
      vue: computeSourceCode(vue),
      react: computeSourceCode(react),
      html: computeSourceCode(html),
    }),
    [vue, react, html, computeSourceCode]
  );

  const copyToClipboard = () => {
    switch (tab) {
      case 'HTML': {
        navigator.clipboard.writeText(computeSourceCode(html));

        return;
      }
      case 'React': {
        navigator.clipboard.writeText(computeSourceCode(react));

        return;
      }
      case 'VueJS': {
        navigator.clipboard.writeText(computeSourceCode(vue));

        return;
      }
      default: {
        return;
      }
    }
  };

  const [subscribeModalVisible, setSubscribeModalVisible] = useState(false);

  const openSubscribeModal = () => {
    setSubscribeModalVisible(true);

    Cookies.set(SUBSCRIBE_COOKIE, true, { expires: 365 });
  };

  const closeSubscribeModal = () => setSubscribeModalVisible(false);

  useEffect(() => {
    if (tab !== tabs[0] && !Cookies.get(SUBSCRIBE_COOKIE)) {
      openSubscribeModal();
    }
  }, [tab, tabs[0]]);

  const onSubscribe = () => setTimeout(closeSubscribeModal, 500);

  return (
    <div className="overflow-hidden bg-white border border-gray-400 rounded shadow-sm">
      {subscribeModalVisible && (
        <Modal close={closeSubscribeModal}>
          <div className="text-center">
            <Image
              fluid={modalImage.childImageSharp.fluid}
              draggable={false}
              alt="Kitwind"
              objectFit="contain"
              className="w-20 mx-auto"
            />
            <h3 className="mt-4 mb-4 text-2xl font-extrabold leading-none">
              Don't miss new UI kits
            </h3>
            <p className="mb-6 text-base text-gray-700 sm:text-base md:text-lg lg:text-base">
              We will be very grateful if you will subscribe to our newsletter
              so that we could keep you up to date with new releases, articles,
              and freebies.
            </p>
            <SubscribeForm withLabel={false} onSubmit={onSubscribe} />
          </div>
        </Modal>
      )}
      <div className="p-3 space-x-2 border-b border-gray-400 sm:space-x-4">
        {tabs.map((item, index) => (
          <TabButton
            key={index}
            title={item}
            active={tab === item}
            onClick={() => setTab(item)}
          >
            {item}
          </TabButton>
        ))}
      </div>
      <div className="relative">
        {tab !== 'Preview' && !hideCopyFor.includes(tab) && (
          <div className="absolute top-0 right-0 mt-5 mr-5">
            <button
              title={`Copy ${tab} code`}
              aria-label={`Copy ${tab} code`}
              className="flex items-center px-4 py-2 font-semibold text-white transition-all duration-200 bg-indigo-800 rounded shadow-lg focus:outline-none focus:shadow-outline hover:bg-indigo-900"
              onClick={copyToClipboard}
            >
              <svg
                className="w-5 mr-2"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeLinejoin="round"
              >
                <polyline points="17,2 22,2 22,23 2,23 2,2 7,2" />
                <path d="M7,1v6h3 c0-1.105,0.895-2,2-2s2,0.895,2,2h3V1H7z" />
              </svg>
              Copy
            </button>
          </div>
        )}
        <Tab
          vue={sources.vue}
          react={sources.react}
          html={sources.html}
          preview={preview}
          tab={tab}
        />
      </div>
    </div>
  );
};
