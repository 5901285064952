import React from 'react';
import { useKeyDown } from '../hooks/useKeyDown';

export const Modal = ({ children, close }) => {
  useKeyDown(['Escape'], close);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity cursor-default"
          tabIndex="0"
          role="button"
          aria-pressed="false"
          onClick={close}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
        &#8203;
        <div
          className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="relative">
            <button
              onClick={close}
              className="absolute top-0 right-0 p-1 -mt-3 -mr-3 rounded focus:outline-none focus:shadow-outline"
            >
              <svg className="w-3 h-3 text-gray-500" viewBox="0 0 16 16">
                <path
                  fill="currentColor"
                  d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"
                />
              </svg>
            </button>
            <div className="pt-3">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
